<template>
  <div></div>
</template>
<script>
import ApiHelper from "@/helper/apihelper";
import Console from "@/console";
import FormatHelper from "@/helper/formathelper";
import { EventBus } from "@/components/eventbus";

export default {
  name: "ExtractCallback",
  data() {
    return {
      title: "Reducer",
      organisationName: "the organisation"
    };
  },
  async mounted() {
    EventBus.$emit("show-analysis-toast");

    let type = this.$route.params.platform;
    let params = this.$route.query;

    await this.$router.replace({ name: "dashboard" }).then(() => {
      if (this.canStartExtract(type, params)) {
        this.completeConnection(type, params);
      } else {
        this.showFailure();
      }
    });
  },
  methods: {
    canStartExtract(type, args) {
      var can = false;
      switch (type) {
        case "xero":
          can = this.validXero(args);
          break;
        case "quickBooks":
          can = this.validQuickBooks(args);
          break;
      }
      return can;
    },

    validXero(args) {
      // xero - {"oauth_token":"","oauth_verifier":"","org":""}
      if (!args["code"]) {
        Console.log("Missing code param in url");
      } else if (!args["scope"]) {
        Console.log("Missing scope param in url");
      } else if (!args["session_state"]) {
        Console.log("Missing session_state param in url");
      } else if (!args["state"]) {
        Console.log("Missing state param in url");
      } else if (!localStorage.getItem("state")) {
        Console.log("Missing state key in local storage");
      } else if (args["state"] != localStorage.getItem("state")) {
        Console.log("State in url and local storage doesn't match");
      }
      return (
        args["code"] &&
        args["scope"] &&
        args["session_state"] &&
        args["state"] == localStorage.getItem("state")
      );
    },
    validQuickBooks(args) {
      if (!args["code"]) {
        Console.log("Missing code param in url");
      } else if (!args["state"]) {
        Console.log("Missing state param in url");
      } else if (!args["realmId"]) {
        Console.log("Missing realmId param in url");
      }
      // quickbooks -  {"state":"","code":"","realmId":""}
      return args["state"] && args["code"] && args["realmId"];
    },

    async completeConnection(type, params) {
      let paramString = encodeURI(JSON.stringify(params));
      let client = await ApiHelper.http();
      await client
        .get(
          `${ApiHelper.endPoint()}connections/complete?platform=${type}&callback=extract&params=${paramString}`
        )
        .then((response) => {
          Console.log(response);
          if (response.data.organisationList == null) {
            if (
              response.data.organisationName &&
              response.data.organisationName != typeof undefined
            ) {
              this.organisationName = FormatHelper.orgShortName(response.data.organisationName);
            }
            this.startNewExtract(type, response.data.organisationId);
          } else {
            this.showUnknownOrgModal(type, response.data.organisationList);
          }
        })
        .catch((e) => {
          Console.log(e);
          this.showFailure();
        });
    },

    orgSelected(selected, type) {
      if (selected != null) {
        this.startNewExtract(type, selected);
      }
    },

    async startNewExtract(type, organisationId) {
      this.$bvModal.hide("extract-unknown-org-modal");

      let client = await ApiHelper.http();
      await client
        .post(
          `${ApiHelper.endPoint()}extracts/start?platform=${type}&organisationId=${organisationId}&returnOrg=true`
        )
        .then((response) => {
          Console.log("New extract response", response);
          this.showSuccess();
          EventBus.$emit("new-extract-success", response.data.organisation, response.data.link);
        })
        .catch((e) => {
          Console.log(e);
          if (e.response.status == 409) {
            this.showDuplicate();
          } else {
            this.showFailure();
          }
        })
        .finally(() => EventBus.$emit("analysis-callback-complete"))
    },

    showSuccess() {
      EventBus.$emit("hide-analysis-toast");
      EventBus.$emit("show-toast", {
        message: `Analysis for ${this.organisationName} has been started.`,
        variant: "success"
      });
    },

    showFailure() {
      EventBus.$emit("analysis-callback-complete");
      EventBus.$emit("hide-analysis-toast");
      EventBus.$emit("show-global-modal", "extract-fail-modal", {
        organisationName: this.organisationName
      });
    },

    showDuplicate() {
      EventBus.$emit("analysis-callback-complete");
      EventBus.$emit("hide-analysis-toast");
      EventBus.$emit("show-toast", {
        message: `There's an analysis in progress for ${this.organisationName} already.`,
        variant: "info",
        title: "Analysis in progress"
    });
    },

    showUnknownOrgModal(type, organisations) {
      EventBus.$emit("show-global-modal", "extract-unknown-org-modal", {
        organisations: organisations,
        image: type,
        message: `Succesfully connected to ${
          type[0].toUpperCase() + type.slice(1)
        }. Please confirm an organisation to analyse:`,
        selected: null,
        onInput: this.orgSelected,
        onClose: this.closedUnknownOrgModal
      });
    },

    async closedUnknownOrgModal() {
      const client = await ApiHelper.http();
      await client
        .post(
          `${ApiHelper.endPoint()}notifications`, {
            snsTopics: ["SNSError"],
            subject: "Could not start extract - user failed to select an extract organisation",
            message: "{userEmail} was prompted to select an organisation to extract - this happens when we cannot identify the organisation being onboarded."
            + " The user has closed the modal, therefore an extract was not started."
          }
        )
        .catch((e) => {
          console.log(e);
        })
    }
  }
};
</script>